import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300 mb-4">
        Guess the Enstars character name or surname in a limited amount of
        tries. All names use the wiki's spelling variant.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        After each guess, the color of the tiles will change to show how close
        your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="I" status="correct" />
        <Cell value="S" />
        <Cell value="A" />
        <Cell value="R" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter I is in the name and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="I" />
        <Cell value="Z" />
        <Cell value="U" status="present" />
        <Cell value="M" />
        <Cell value="I" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter U is in the name but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="T" />
        <Cell value="O" />
        <Cell value="M" />
        <Cell value="O" status="absent" />
        <Cell value="Y" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter O is not in the name in any spot.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 mt-4">
        Remixed by{' '}
        <a className="underline" href="https://twitter.com/findermao">
          @findermao
        </a>
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 ">
        Based off the awesome{' '}
        <a
          className="underline"
          href="https://github.com/cwackerfuss/react-wordle"
        >
          react-wordle
        </a>
      </p>
    </BaseModal>
  )
}
