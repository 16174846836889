import { solution } from '../lib/words'

export const MAX_WORD_LENGTH = 9
// export const MAX_CHALLENGES = 4
export const ALERT_TIME_MS = 2000
export const REVEAL_TIME_MS = 50

const tries: { [index: string]: any } = {
  '3': 4,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 5,
  '8': 4,
  '9': 2,
}

const getWordOfDay = () => {
  return {
    MAX_CHALLENGES: tries[solution.length.toString()],
  }
}

export const { MAX_CHALLENGES } = getWordOfDay()
