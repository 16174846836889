export const WORDS = [
  'hajime',
  'jin',
  'kaoru',
  'hiiro',
  'sengoku',
  'fushimi',
  'narukami',
  'amagi',
  'mitsuru',
  'shu',
  'tenshouin',
  'madara',
  'hidaka',
  'aira',
  'sazanami',
  'tomoe',
  'midori',
  'tenma',
  'hokuto',
  'aoi',
  'amagi',
  'mao',
  'shiina',
  'takamine',
  'eichi',
  'hakaze',
  'tetora',
  'sora',
  'himemiya',
  'shino',
  'kanata',
  'niki',
  'yuta',
  'kuro',
  'mashiro',
  'izumi',
  'kunugi',
  'tori',
  'mika',
  'tatsumi',
  'otogari',
  'akehoshi',
  'akiomi',
  'sena',
  'jun',
  'tsukinaga',
  'aoba',
  'makoto',
  'mikejima',
  'ran',
  'kagehira',
  'tsukasa',
  'sagami',
  'oukawa',
  'hiyori',
  'saegusa',
  'rei',
  'tsumugi',
  'himeru',
  'natsume',
  'chiaki',
  'ayase',
  'shinkai',
  'ritsu',
  'yuuki',
  'hasumi',
  'kiryu',
  'adonis',
  'nagisa',
  'hinata',
  'mayoi',
  'wataru',
  'ibara',
  'shiratori',
  'itsuki',
  'yuzuru',
  'suou',
  'kanzaki',
  'hibiki',
  'hidaka',
  'morisawa',
  'nagumo',
  'sakuma',
  'kohaku',
  'nazuna',
  'harukawa',
  'aoi',
  'sakasaki',
  'rinne',
  'nito',
  'subaru',
  'leo',
  'seiya',
  'tomoya',
  'koga',
  'keito',
  'kazehaya',
  'sakuma',
  'souma',
  'shinobu',
  'oogami',
  'isara',
  'arashi',
]
